export const calendarColumnAttributes = {
  dataModelFieldName: 'date',
  type: 'calendar',
  width: 100,
  align: 'left',
  options: {
    format: 'dd.mm.yyyy',
    placeholder: 'dd.mm.yyyy',
    readonly: false,
    today: false,
    startingDay: 1,
    textDone: 'OK',
    validRange: ['1500-01-01', '9999-12-31'],
    // other calendar options are here: https://bossanova.uk/jspreadsheet/v3/docs/getting-started
  },
};

export function handleDate(date) {
  if (!date) return date;
  // if date contains space, assume this is the following string '2015-04-01 00:00:00'
  const localDate = date.indexOf(' ') > -1 ? `${date.replace(' ', 'T')}Z` : date;
  // if date was picked from the calendar widget, it will be in the ISO8601 format
  if (localDate.includes('Z')) return new Date(`${date.split('Z')[0]}Z`).setUTCHours(0, 0, 0, 0);
  // if user pasted the date, we assume it is in the same format as displayed in the table (DD.MM.YYYY)
  // user-pased date is interpreted as a CET date (midnight), so when we convert it to UTC, it will be the previous day 10 or 11 pm
  let pastedDate = new Date(date.split('.')[2], Number(date.split('.')[1]) - 1, date.split('.')[0]);
  pastedDate = pastedDate.setTime(pastedDate.getTime() - pastedDate.getTimezoneOffset() * 60 * 1000);
  return pastedDate;
}

const convertableTypes = [
  'currency',
  'currency0Decimals',
  'currency2Decimals',
  'currency4Decimals',
  'secondaryCurrency0Decimals',
  'secondaryCurrency2Decimals',
  'secondaryCurrency',
  'number',
  'number0Decimals',
  'number2Decimals',
  'number4Decimals',
];

// change date format, remove empty strings, convert amounts (strings, with mask) to numbers
// leave the remaining properties unchanged
// bases on gridLayout, so it requires the transaction object to have the exact same sequence of keys as specified the gridLayout
// if this becomes a problem, use Map instead of Object
export function removeGridFormatting(transactionArray, gridLayout) {
  // this regex removes all non-numerical characters from a string
  const amountRegex = /[^0-9.}-]+/g;

  return transactionArray.map((transaction) => {
    const newTransaction = { ...transaction };
    Object.keys(newTransaction).forEach((key, index) => {
      // replace '' with nulls
      if (String(newTransaction[key]).length === 0) newTransaction[key] = null; // numbers don't have .length, so String()
      // convert ISO dates to epoch
      if (gridLayout[index]?.type === 'date') newTransaction[key] = handleDate(newTransaction[key]);
      // convert amounts to numbers (for predetermined column types)
      console.log('DEBUG removeGridFormatting', key, gridLayout[index]);
      if (convertableTypes.includes(gridLayout[index]?.type)) {
        console.log('DEBUG removeGridFormatting', key, newTransaction[key]);
        newTransaction[key] = Number(String(newTransaction[key]).replace(amountRegex, ''));
      }
    });
    return newTransaction;
  });
}

export function compare(a, b, propertyName, asc = true) {
  // console.log('DEBUG compare', a, b, propertyName, asc);
  const multiplier = asc ? 1 : -1;
  // so that it doesn't sort capital letters separately from lowercase
  const elementA = typeof a[propertyName] === 'number' ? a[propertyName] : a[propertyName].toLowerCase();
  const elementB = typeof b[propertyName] === 'number' ? b[propertyName] : b[propertyName].toLowerCase();

  if (elementA < elementB) {
    return -1 * multiplier;
  }
  if (elementA > elementB) {
    return 1 * multiplier;
  }
  return 0;
}
